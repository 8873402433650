<div id="slogan" class="content">
  <p class="slogan">Найди будущее в настоящем</p>
</div>

<div id="activities" class="section content">
  <h1>Направления деятельности</h1>
  <div class="activities">
    <div class="activity activity-blue">
      <p class="activity-description">Разработка систем автоматического управления и  мониторинга в промышленном секторе</p>
    </div>
    <div class="activity">
      <img class="activity-image" src="assets/images/activity-1.webp" />
    </div>
    <div class="activity activity-gray">
      <p class="activity-description">Разработка систем электронного документооборота</p>
    </div>

    <div class="activity">
      <img class="activity-image" src="assets/images/activity-2.webp" />
    </div>
    <div class="activity activity-blue">
      <p class="activity-description">Ремонт и модернизация сложного промышленного оборудования</p>
    </div>
    <div class="activity">
      <img class="activity-image" src="assets/images/activity-3.webp" />
    </div>

    <div class="activity activity-gray">
      <p class="activity-description">Поддержка программного обеспечения</p>
    </div>
    <div class="activity">
      <img class="activity-image" src="assets/images/activity-4.webp" />
    </div>
    <div class="activity activity-blue">
      <p class="activity-description">Разработка высоконагруженных программных систем на базе Web технологий</p>
    </div>
  </div>
</div>

<div id="statistic" class="content">
  <div id="foundation-year">
    <p class="statistic-number">2017</p>
    <p class="statistic-description">Год основания</p>
  </div>
  <div id="total-projects">
    <p class="statistic-number">52</p>
    <p class="statistic-description">Завершенных проекта</p>
  </div>
</div>

<div id="projects" class="section content">
  <h1>Проекты</h1>

  <div class="projects">
    <div *ngFor="let proj of projects" class="project-wrap"
         [routerLink]="['/project', proj.id]">
      <a class="project">
        <div class="project-image">
          <img *ngIf="proj.media.length > 0" src="{{ proj.media[0] }}" />
        </div>
        <div class="project-title">{{ proj.title }}</div>
      </a>
    </div>
  </div>
  <a mat-button color="primary" routerLink="/projects">Посмотреть все проекты</a>
</div>

<div id="clients" class="section content">
  <h1>Клиенты и партнеры</h1>
  <div class="clients">
    <div class="client">
      <img src="assets/images/clients/bermudas.svg"  alt="Бермуды - Студия дизайна"/>
    </div>
    <div class="client">
      <img src="assets/images/clients/solorum_group.svg"  alt="Solorum Group"/>
    </div>
    <div class="client">
      <img src="assets/images/clients/rm.webp"  alt="Русская механика"/>
    </div>
    <div class="client">
      <img src="assets/images/clients/saturn.webp"  alt="ПАО ОДК Сатурн"/>
    </div>
    <div class="client">
      <img src="assets/images/clients/gt.webp"  alt="АО ОДК Газовые турбины"/>
    </div>
    <div class="client">
      <img src="assets/images/clients/tenzosensor.webp"  alt="НПП Тензосенсор"/>
    </div>
  </div>
</div>

<div id="contacts" class="section content">
  <h1>Контакты</h1>

  <div class="contact-map">
    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A6e8ba9a690a7ce3b8390465f21b13a72dc13f3e5e484fc26cf830b74737b4b04&amp;source=constructor" width="100%" height="100%" frameborder="0"></iframe>
  </div>

  <div class="grid">
    <div class="grid-column">
      <h2>Центральный офис</h2>
      <p>ул. Пушкина, 53, офис 408,<br>
        152934, Ярославская обл, г. Рыбинск</p>
      <p>
        E-mail: <a href="mailto:info@vsplat.ru">info@vsplat.ru</a>
      </p>

      <h2>Генеральный директор</h2>
      <p>
        <strong>Котляр Дмитрий Игоревич</strong>
      </p>
      <p>
        E-mail: <a href="mailto:dkotlyar@vsplat.ru">dkotlyar@vsplat.ru</a>
      </p>
      <p>
        Telegram: <a href="https://t.me/dkotlyar">@dkotlyar</a>
      </p>
    </div>
    <div class="grid-column">
      <h2>Хотите у нас работать?</h2>
      <p>
        Пожалуйста, отправьте резюме и сопроводительное письмо по адресу <a href="mailto:info@vsplat.ru">info@vsplat.ru</a>
      </p>
    </div>
  </div>

</div>
