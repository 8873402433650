import {Component, OnInit} from '@angular/core';
import {ProjectsService} from '../../services/projects.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css', '../../common.css', '../project-list/project-list.component.css'],
    providers: [ProjectsService]
})
export class HomeComponent implements OnInit {

    projects = [];
    projectsHaveMore = false;

    constructor(private projectService: ProjectsService) {
    }

    ngOnInit(): void {
        const projectsSizePerPage = 4;

        this.projectService.getProjects(0, projectsSizePerPage).subscribe(
            projects => {
                this.projects = projects.value;
                this.projectsHaveMore = projects.total > projectsSizePerPage;
            }, error => {
                console.error(error);
            }
        );
    }

}
