<div class="images">
    <ng-container *ngFor="let img of images; let i = index;">
      <img [hidden]="i !== imageIndex" src="{{ images[i] }}" (click)="openInDialog()" />
    </ng-container>

    <div *ngIf="images.length > 1" class="switch-image">
        <div class="next-image" (click)="nextImage()"></div>
        <div class="prev-image" (click)="prevImage()"></div>

        <div *ngFor="let item of [].constructor(images.length); let i = index"
             class="switch-image-circle"
             [class.selected]="i == imageIndex"
             (click)="imageIndex = i"></div>
    </div>
</div>

