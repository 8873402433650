<div class="section content">
    <h1>Проекты</h1>

    <div class="projects">
        <div *ngFor="let proj of projects" class="project-wrap"
             [routerLink]="['/project', proj.id]">
            <a class="project">
                <div class="project-image">
                    <img *ngIf="proj.media.length > 0" src="{{ proj.media[0] }}" />
                </div>
                <div class="project-title">{{ proj.title }}</div>
            </a>
        </div>
    </div>

    <mat-paginator [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="changePage($event)">
    </mat-paginator>
</div>
