import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';

import {PROJECTS} from '../data/projects.data';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor() { }

  public getProjects(page: number, pageSize: number): Observable<any> {
    return of({
      value: PROJECTS.slice(page * pageSize, (page + 1) * pageSize),
      total: PROJECTS.length,
      page
    });
  }

  public getProject(projectId: string): Observable<any> {
    return of(PROJECTS.find(prj => prj.id === projectId));
  }
}
