import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './routes/main/home.component';
import {MatButtonModule} from '@angular/material/button';
import {ProjectListComponent} from './routes/project-list/project-list.component';
import {RouterModule, Routes} from '@angular/router';
import {ProjectDetailsComponent} from './routes/project-details/project-details.component';
import {PhotoViewerComponent} from './components/photo-viewer/photo-viewer.component';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';

const appRoutes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'projects', component: ProjectListComponent},
    {path: 'project/:id', component: ProjectDetailsComponent},
    {path: '**', component: HomeComponent}
];


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ProjectListComponent,
        ProjectDetailsComponent,
        PhotoViewerComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled', // or 'top'
            anchorScrolling: 'enabled',
            scrollOffset: [0, 166] // [x, y] - adjust scroll offset
        }),
        MatButtonModule,
        MatDialogModule,
        MatPaginatorModule
    ],
    providers: [
        {provide: MatDialogRef, useValue: {}},
        {provide: MAT_DIALOG_DATA, useValue: []}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
