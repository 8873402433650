<div *ngIf="project != undefined else loadingProject" class="section content">
    <h1>{{project.title}}</h1>

    <div class="project-client" *ngIf="project.client && project.client.length > 0">
        <strong>Заказчик:</strong> <span [innerHTML]="project.client"></span>
    </div>

    <app-photo-viewer [images]="project.media"></app-photo-viewer>

    <div class="project-description" [innerHTML]="project.description">
    </div>

    <div class="project-techinfo">
        <ul>
            <li *ngFor="let tech of project.tech">
                <strong>{{tech.title}}:</strong>
                <i>{{tech.desc}}</i>
            </li>
            <li *ngIf="project.url && project.url.length > 0">
                <strong>Сайт:</strong>
                <a href="{{project.url}}" target="_blank" tabindex="-1">{{project.url}}</a>
            </li>
        </ul>
    </div>
</div>

<ng-template #loadingProject>
    <div class="section content">
        Загрузка...
    </div>
</ng-template>
