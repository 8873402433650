<header>
  <div class="content">
    <div class="header-logo"></div>
    <menu>
      <li><a routerLink="/">Главная</a></li>
      <li><a routerLink="/" fragment="activities">Направления деятельности</a></li>
      <li><a routerLink="/" fragment="statistic">О нас</a></li>
      <li><a routerLink="/" fragment="projects">Проекты</a></li>
      <li><a routerLink="/" fragment="contacts">Контакты</a></li>
    </menu>
  </div>
</header>

<div class="body">
  <router-outlet></router-outlet>
</div>

<footer>
  <p>&copy; 2020, ООО &laquo;ВС-ПЛАТФОРМА&raquo;</p>
  <p>Комплексный подход в автоматизации систем управления промышленного оборудования.</p>
</footer>
