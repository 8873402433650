import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProjectsService} from '../../services/projects.service';
import {Project} from '../../classes/project';

@Component({
    selector: 'app-project-details',
    templateUrl: './project-details.component.html',
    styleUrls: ['./project-details.component.css', '../../common.css']
})
export class ProjectDetailsComponent implements OnInit {

    project: Project;

    constructor(private route: ActivatedRoute,
                private projectService: ProjectsService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(data => {
            this.projectService.getProject(data.id).subscribe(
                project => {
                    this.project = project;
                },
                error => {
                    console.error(error);
                }
            );
        });
    }

}
