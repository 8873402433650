import {Project} from '../classes/project';

export const PROJECTS: Array<Project> = [
    {
        id: 'dennaplatforme',
        url: 'http://www.dennaplatforme.com/',
        title: 'Разработка клиентской части интерактивного портала ко дню открытых дверей для детей сотрудников шельфовых ' +
            'активов «Путешествие на «Приразломную»',
        client: 'ООО &laquo;Солорум Групп&raquo;, студия дизайна &laquo;Бермуды&raquo;',
        tech: [
            {
                title: 'Фреймворк',
                desc: 'Angular 11'
            }, {
                title: 'Язык программирования',
                desc: 'TypeScript'
            }, {
                title: 'Год разработки',
                desc: '2020'
            }
        ],
        media: [
            '/assets/projects/dennaplatforme/main.png', '/assets/projects/dennaplatforme/main-popup.png',
            '/assets/projects/dennaplatforme/main-leve2.png', '/assets/projects/dennaplatforme/loading.png',
            '/assets/projects/dennaplatforme/livestream.png',
            '/assets/projects/dennaplatforme/functions.png', '/assets/projects/dennaplatforme/functions-popup.png',
            '/assets/projects/dennaplatforme/location.png', '/assets/projects/dennaplatforme/location-2.png',
            '/assets/projects/dennaplatforme/location-look.png', '/assets/projects/dennaplatforme/room.png',
            '/assets/projects/dennaplatforme/quiz.png', '/assets/projects/dennaplatforme/quiz-question.png',
            '/assets/projects/dennaplatforme/quiz-question-2.png', '/assets/projects/dennaplatforme/quiz-correct.png',
            '/assets/projects/dennaplatforme/quiz-incorrect.png', '/assets/projects/dennaplatforme/quiz-gift.png',
            '/assets/projects/dennaplatforme/quiz-result.png'
        ],
        description:
            '<p>Информационный сайт, разработанный с целью проведения Дня открытых дверей для детей сотрудников шельфовых ' +
            'активов «Путешествие на «Приразломную».</p>' +

            '<p>Задача проекта: ознакомить детей сотрудников шельфовых активов с основными направлениями работы компании.</p>' +

            '<p>На сайте реализована интерактивная карта с пинами, состоящая из двух уровней, страница с описанием функций работников ' +
            'МЛСП &laquo;Приразломная&raquo;, страницы с описанием объектов МЛСП &laquo;Приразломная&raquo;, комната с мастер-классами ' +
            '(видео на Youtube), викторина с делением по возрастным группам: 4-7 лет, 8-11 лет, 12-15 лет. На каждой странице ' +
            'присутствует озвучка с анимацией Нефтячка и фоновая музыка.</p>' +

            '<p>Загрузка приложения происходит в три этапа: 1) загружается основной материал, использующийся на всех страницах; ' +
            '2) в очередь на загрузку помещаются все медиа-файлы; 3) при открытии страниц происходит подгрузка материала, который ещё ' +
            'не успел закэшироваться в фоне. На время загрузки появляется заставка с полосой загрузки и указанием процента загрузки.</p>' +

            '<p>Графический материал, наполнение страниц, озвучка были проработаны партнерами проекта: ' +
            '&laquo;<a href="https://solorum.ru/" target="_blank">Solorum Group</a>&raquo; и ' +
            'студией дизайна &laquo;<a href="https://bermudas.pro/" target="_blank">Бермуды</a>&raquo;.</p>'
    } as Project,
    {
        id: 'fpl432',
        title: 'Модернизация линии FPI для проведения контроля флуоресцентными проникающими веществами в автоматизированном режиме',
        client: 'ПАО &laquo;ОДК &ndash; Сатурн&raquo;',
        media: ['assets/projects/fpl432/mnemonic.png', 'assets/projects/fpl432/main.png', 'assets/projects/fpl432/calibrate.png',
            'assets/projects/fpl432/manual.png', 'assets/projects/fpl432/mounting-panels.jpg', 'assets/projects/fpl432/team.jpg'],
        tech: [
            {
                title: 'Платформа',
                desc: 'Bosch Rexroth'
            },
            {
                title: 'Фреймворк',
                desc: 'Quarkus, Angular'
            },
            {
                title: 'Языки программирования',
                desc: 'IEC 61131-3 (Codesys ST), Java, TypeScript'
            },
            {
                title: 'Год разработки',
                desc: '2020'
            }
        ],
        description:
            '<p>До недавнего времени существовало два отдельных мира &ndash; мир автоматизации производственных процессов на базе ПЛК и ' +
            'SCADA-систем и мир автоматизации бизнес процессов предприятия на базе микросервисов в вычислительном облаке.</p>' +

            '<p>В мире микросервисов за последнее время появились множество промышленных стандартов по разработке распределенных ' +
            'приложений и протоколам взаимодействия, появились мощные фреймворки для разработки веб интерфейса, что сильно упростило и ' +
            'удешевило разработку. В мире промышленной автоматизации росли возможности ПЛК и происходило удешевление аппаратных средств. ' +
            'Благодаря этому стало возможным использовать в ПЛК протоколы, свойственные для микросервисной архитектуры, размещать ' +
            'системы управления производственными процессами в облаке в датацентре, использовать удобный веб интерфейс, реализовывать ' +
            'сложные алгоритмы управления оборудованием с помощью мощных распределенных приложений, интегрировать без посредников ' +
            'системы промышленной автоматизации и системы автоматизации бизнес процессов предприятия.</p>' +

            '<p>Подход &laquo;Индустрия 4.0&raquo; позволяет стереть границу между двумя мирами.</p>' +

            '<p>В качестве примера такого подхода можно рассматривать модернизацию линии флуоресцентного пенетрантного контроля для ' +
            'ПАО &laquo;ОДК &ndash; Сатурн&raquo;.</p>' +

            '<p>Автоматическая линия FPI используется для проведения контроля флуоресцентными проникающими веществами в ' +
            'автоматизированном режиме деталей изделия SaM146, деталей фирмы LEAP. Автоматическая линия поставлена изготовителем на ' +
            'предприятие в 2011 году. Необходимость капремонта установки вызвана неверными техническими решениями, которые приводили к ' +
            'частыми поломками установки.</p>' +

            '<p>Линия флуоресцентного проникающего контроля состоит из 18 ванн различных типов: нанесение и стекание проявителя, ' +
            'эмульгатора, мойки, сушки, нанесение и обдув проявителя, позиции загрузки и выгрузки; системы водоподготовки, которая ' +
            'состоит из нескольких баков различных типов воды, нагревателей и очистителей. ' +
            'В  ванны при помощи двух манипуляторов устанавливаются корзины с лопатками турбины, ванны расположены вдоль двух соседних стен. ' +
            'Манипуляторы перемещаются по рельсам в двух координатах: вдоль стен и вверх-вниз, имеют пневматические захваты. ' +
            'Перемещение манипулятора вдоль вертикальной и горизонтальной осей осуществляется при помощи сервостистемы Bosch Rexroth. ' +
            'На ваннах используются такие исполнительные механизмы как нагреватели, пневмоцилиндры, насосы, клапаны, ' +
            'импеллер и шаговый двигатель. Помимо исполнительных механизмов присутствуют датчики уровня жидкости, концевые датчики, ' +
            'герконы, датчики температуры.</p>' +

            '<p>Система управления линии состоит из двух блоков:</p>' +

            '<ol>' +
            '<li>ПЛК блок, занимающийся непосредственным управлением оборудованием.' +
            '<ol>' +
            '<li>В основе системы управления &ndash; программируемый логический контроллер (ПЛК) Bosch Rexroth, который управляет ' +
            'исполнительными механизмами, считывает показания с датчиков, выполняет технологическую программу и распределенная ' +
            'система модулей ввода-вывода данных в цифровой и аналоговой форме</li>' +
            '<li>Программа для ПЛК Bosch Rexroth разработана с применением объектно-ориентированного подхода. ' +
            'Программа поддерживает 3 режима работы: ' +
            '<ul>' +
            '<li>автоматический режим &ndash; происходит автоматическое выполнение технологических программ, оператор занимается ' +
            'запуском технологических программ и контролем их выполнения;</li>' +
            '<li>ручной режим - оператор передает команды на выполнение тех или иных действий, например: открытие крышки, ' +
            'запуск насоса, перемещение корзины и т.д. Система контролирует действия оператора, все системы программной защиты активны;</li>' +
            '<li>сервисный режим - данный режим не доступен оператору, доступен только сервисному работнику, в этом режиме ' +
            'происходит управление непосредственно исполнительными механизмами и отображаются значения с датчиков в ' +
            'необработанном режиме, системы программной защиты не работают.</li>' +
            '</ul></li>' +
            '<li>Технологическая программа приходит в ПЛК от компьютера в виде последовательности байт, для передачи данных ' +
            'используется собственный протокол. На основе последовательности байт внутри ПЛК строятся программные блоки в виде объектов. ' +
            'Внутри объектов содержатся данные технологического блока и логика его выполнения. ' +
            'Данный подход позволил легко и быстро программировать и выполнять технологические операции.</li>' +
            '<li>Исполняемый программный блок вызывает методы объектов манипулятора, ванн, системы водоподготовки. ' +
            'Объекты данных физических сущностей передают команды реальным исполнительным механизмам, проведя необходимые ' +
            'проверки для осуществления безопасности самой линии и оператора.</li>' +
            '</ol></li>' +

            '<li>Управляющая программа, реализованная в виде микросервиса, которая позволяет:' +
            '<ol>' +
            '<li>Для технолога задавать технологический процесс.</li>' +
            '<li>Для оператора запускать исполнение технологических процессов, следить за ходом его выполнения, реагировать ' +
            'на возникающие нештатные ситуации, сохранять детальный протокол прохождения котроля изделиями.</li>' +
            '<li>Для сервисного специалиста &ndash; управлять линией на низком уровне, калибровать элементы линии.</li>' +
            '<li>Для администратора безопасности &ndash; управлять пользователями, просматривать детальный протокол работы.</li>' +
            '<li>Микросервис поддерживает возможность аутентификации по протоколу OAuth2/OpenIDConnect, позволяет подключиться ' +
            'внешней системе через REST-сервисы для взаимодействия.</li>' +
            '<li>Микросервис реализован в виде Docker-контейнера, который можно запускать в облачной среде, взаимодействие с ПЛК ' +
            'выполняется через протокол TCP/IP.</li>' +
            '<li>Микросервис реализован на языке программирования Java с использованием фреймвока Quarkus, который позволяет ' +
            'при необходимости преобразовать Java-код в исполняемый файл без необходимости наличия Java Virtual Machine для запуска.</li>' +
            '<li>Веб интерфейс реализован на базе фреймворка Angular. Предоставляет удобный, красивый пользовательский интерфейс ' +
            'с отображением этапов технологического процесса.</li>' +
            '<li>При необходимости могут быть поддержаны возможности масштабирования (запуска нескольких экземпляров) для ' +
            'повышения надежности системы управления и её отказоустойчивости.</li>' +
            '</ol></li>' +
            '</ol>' +

            '<p>Модернизированная линия флуоресцентного пенетрантного контроля введена в эксплуатацию и успешно работает в ' +
            'корпусе №49 ПАО &laquo;ОДК &ndash; Сатурн&raquo;.</p>'
    } as Project,
    {
        id: 'tcs-web',
        title: 'Web-приложение, расширяющее возможности информационной системы TechnologiCS (TechnologiCS Web)',
        client: 'АО &laquo;ОДК &ndash; Газовые турбины&raquo;',
        media: ['assets/projects/tcs-web/journal-pt.jpg', 'assets/projects/tcs-web/journal-psp.jpg',
            'assets/projects/tcs-web/journal-sz.jpg', 'assets/projects/tcs-web/report-pt.jpg', ],
        tech: [
            {
                title: 'Язык программирования',
                desc: 'Java, TypeScript'
            },
            {
                title: 'Фреймворк',
                desc: 'Quarkus, Angular'
            },
            {
                title: 'База данных',
                desc: 'PostgreSQL, MS SQL Server'
            },
            {
                title: 'Интеграционные продукты',
                desc: 'TechnologiCS'
            },
            {
                title: 'Дополнительное ПО',
                desc: 'Docker'
            },
            {
                title: 'Год разработки',
                desc: '2020'
            }
        ],
        description:
            '<p>На большинстве крупных промышленных предприятий уже давно используются различные системы для управления информацией ' +
            'о продукции. Так, на АО &laquo;ОДК &ndash; Газовые турбины&raquo; одной из таких систем является TechnologiCS.</p>' +

            '<p>Одним из недостатков данной системы является то, что добавление в неё нового функционала является сложной задачей, ' +
            'т. к. придётся разрабатывать его с помощью Visual Basic 6.0, который на данный моменты устарел и ' +
            'квалифицированных специалистов на рынке очень мало.' +
            'Для АО &laquo;ОДК &ndash; Газовые турбины&raquo; является важным решение задачи автоматизированного заполнения информации о ' +
            'фактическом производстве продукции.</p>' +

            '<p>Для решения поставленной задачи было разработано web-приложение, которое интегрировано в TechnologiCS и собирает из ' +
            'него всю необходимую информацию о номенклатуре, работниках и т. д. Пользователи системы вносят необходимые данные о ' +
            'фактическом производстве того или иного изделия, отмечают параметры и характеристики производства. ' +
            'В данной системе реализован функционал для планирования рабочего процесса, а также для формирования печатных форм и отчётов ' +
            'различной сложности.</p>' +

            '<p>Одним из ключевых модулей системы является "Подготовка к окраске изделия", в которой маляр отмечает различные ' +
            'характеристики лакокрасочного материала (ЛКМ), такие как: основа, отвердитель, растворитель, количество используемых ' +
            'материалов и т. д. По введенной информации, а также по информации о цехе пользователя, автоматически подбирается ' +
            'жизнеспособность ЛКМ. При использовании подготовленных ЛКМ выполняется проверка пригодности материала к использованию, ' +
            'что позволят увеличить качество лакокрасочного покрытия. После того, как изделие будет покрашено, в базе данных останется ' +
            'вся информация об окраске изделии, а именно, кто, когда, чем и при каких условиях занимался окраской, что позволяет ' +
            'руководству отслеживать качество выполняемых работ на производстве.</p>' +

            '<p>Использование современных технологий при разработке позволяет легко масштабировать приложение, в приложение ' +
            'постоянно добавляется новый функционал, который покрывает новые аспекты производства изделий на предприятии.</p>'
    } as Project,
    {
        id: 'notes',
        title: 'Web-приложение «Служебные записки»',
        client: 'АО &laquo;ОДК &ndash; Газовые турбины&raquo;',
        media: ['assets/projects/notes/journal.jpg', 'assets/projects/notes/creating.jpg', 'assets/projects/notes/reports.jpg'],
        tech: [
            {
                title: 'Язык программирования',
                desc: 'Java, TypeScript'
            },
            {
                title: 'Фреймворк',
                desc: 'Quarkus, Angular'
            },
            {
                title: 'База данных',
                desc: 'PostgreSQL'
            },
            {
                title: 'Дополнительное ПО',
                desc: 'Docker'
            },
            {
                title: 'Год разработки',
                desc: '2019'
            }
        ],
        description: '<p>Большинство промышленных предприятий занимается производством досточно сложных изделий. ' +
            'Сложных как с точки зрения технологической, так и конструкторской части. Периодически в конструкторскую документацию ' +
            'изделия вносят изменения, когда производство его уже налажено. В таких случаях, для того чтобы изменение вступили в силу ' +
            'на производстве, выпускают извещение на изменение, формирования которого занимает значительное количество рабочего времени.</p>' +

            '<p>Для решения данной проблемы, для АО &laquo;ОДК &ndash; Газовые турбины&raquo; было разработано web-приложение, ' +
            'которое позволяет в электронной форме оперативно составлять и отправлять служебные записки с информацией об изменениях ' +
            'всем задействованным в данном процессе работникам.</p>' +

            '<p>В служебной записке выполняется заполнение полей с информацией, а также можно приложить файлы различного типа.</p>' +

            '<p>Функционал сервиса служебных записок дополняет функционал по формированию печатных форм и отчётов высокой сложности.</p>'
    } as Project,
    {
        id: 'bu-urp',
        title: 'Блок управления унифицированной роботизированной платформой (БУ УРП)',
        client: 'НПП &laquo;Тензосенсор&raquo;',
        media: ['assets/projects/bu-urp/tinger.jpg', 'assets/projects/bu-urp/car.jpg', 'assets/projects/bu-urp/structure-2.png',
            'assets/projects/bu-urp/structure.png'],
        tech: [
            {
                title: 'Платформа',
                desc: 'Atmel ATmega2560'
            },
            {
                title: 'Язык программирования',
                desc: 'C'
            },
            {
                title: 'Год разработки',
                desc: '2019'
            }
        ],
        description: '<p>ООО &laquo;НПП &laquo;Тензосенсор&raquo; разрабатывает роботизированный комплекс для тушения лесных пожаров, ' +
            'построенный на основе гусеничного мотовездехода Tinger Scout. Частью системы управления унифицированной роботизированной ' +
            'платформы является блок управления унифицированной роботизированной платформой (БУ УРП).</p>' +

            '<p>Мотовездеход Tinger Scout приводится в движение двухцилиндровым карбюраторным бензиновым двигателем Lifan LF2V78F-2A. ' +
            'Крутящий момент с вала двигателя передается через ременной вариатор на механическую коробку передач, имеющую две передачи ' +
            'переднего хода (повышенную и пониженную), передачу заднего хода и нейтраль. От коробки передач момент передается через ' +
            'бортовые фрикционы на левый и правый гусеничные движители. Бортовые фрикционы оснащены тормозными колодками, позволяющими ' +
            'подтормаживать отдельно левую и правую гусеницы.</p>' +

            '<p>В состав системы управления БУ УРП входят: электронный блок управления, электроприводы воздействия на механические ' +
            'органы управления (руль, тормоз, газ, подсос, КПП), блок реле (в составе БУ УРП) для подмены электрических органов ' +
            'управления (замок зажигания, выключатели), плата телеметрии для отслеживания состояния мотовездехода по датчикам.</p>' +

            '<p>Управление приводами осуществляется на основе команд, получаемых от оператора по радиоканалу. Система поддерживает ' +
            'ручной и автоматизированный режим работы. В ручном режиме исполнительные механизмы отключены и не блокируют органы ручного ' +
            'управления УРП. В автоматизированном режиме управление УРП осуществляется при помощи исполнительных механизмов. ' +
            'Переход из ручного в автоматизированный режим сопровождён калибровкой всех приводов. В результате калибровки происходит ' +
            'определение нулевых точек для приводов. С блока осуществляется управления зажиганием, стартером, подсосом карбюраторного ' +
            'ДВС, фарами. Пуск ДВС осуществляется в автоматизированном режиме по команде оператора, отключение стартера после запуска ' +
            'двигателя происходит по датчику оборотов. Управление подсосом так же имеет ручной и автоматический режим.</p>' +

            '<p>Электронный блок управления построен на микроконтроллере Atmega2560. В рамках данного проекта была разработана программа ' +
            'для микроконтроллера, осуществляющая управление всеми исполнительными механизмами УРП, сбор информации с датчиков и платы ' +
            'телеметрии, управление подчиненными блоками, установленными на УРП. К таким блокам относятся: блок управления системой ' +
            'технического зрения, блок управления бортовой энергосистемы, блок управления навесным технологическим оборудованием. ' +
            'Данные блоки связаны с блоком УРП по шине RS-485, протокол Modbus RTU.</p>' +

            '<p>Программное обеспечение разработано на языке C, в среде разработки Atmel Studio 6.2. ' +
            'При разработке программы использовалась библиотека yaMBSiavr, реализующая ModBus Slave на микроконтроллерах Atmega.</p>'
    } as Project,
    {
        id: 'bu-stz',
        title: 'Разработка программного обеспечения блока управления системой технического зрения унифицированной роботизированной платформы',
        client: 'НПП &laquo;Тензосенсор&raquo;',
        media: ['assets/projects/bu-urp/tinger.jpg', 'assets/projects/bu-stz/structure.png'],
        tech: [
            {
                title: 'Платформа',
                desc: 'Atmel ATmega2560'
            },
            {
                title: 'Язык программирования',
                desc: 'C'
            },
            {
                title: 'Год разработки',
                desc: '2019'
            }
        ],
        description:
            '<p>ООО &laquo;НПП &laquo;Тензосенсор&raquo; разрабатывает роботизированный комплекс для тушения лесных пожаров, ' +
            'построенный на основе гусеничного мотовездехода Tinger Scout. Частью системы управления унифицированной роботизированной ' +
            'платформы является блок управления системой технического зрения (БУ СТЗ).</p>' +

            '<p>Система технического зрения содержит: основную камеру, приводимую в движение по двум осям при помощи ' +
            'шаговых двигателей и охлаждаемую при помощи модуля Пельте, четыре камеры кругового обзора системы СПАРК, GPS-приемник ' +
            'Troyka, видеокоммутатор на основной и резервный каналы, омыватели всех камер, датчики температуры основной камеры и ' +
            'внутреннего объема робота.<p>' +

            '<p>Блок управления построен на микроконтроллере Atmega2560. В рамках данного проекта была разработана программа для ' +
            'микроконтроллера, осуществляющая управления всеми исполнительными механизмами системы технического зрения и сбор ' +
            'информации с датчиков. Взаимодействие с системой технического зрения осуществляется по интерфейсу RS-485, ' +
            'протокол передачи данных Modbus RTU.</p>' +

            '<p>В данной системе присутствует возможность изменение поворота и наклона камеры, переключение между основной камерой и ' +
            'системой СПАРК, выбор режима работы системы СПАРК: круговой обзор 360 градусов, либо вид с конкретной камеры, ' +
            'включение омывателя производится для каждой камеры независимо, контроль уровня воды в бачках системы омывания камер ' +
            'производится автоматический, контроль температуры основной камеры производится автоматически, с возможностью ручного ' +
            'управления модулем Пельте, переключение между основным и резервным каналом передачи видео производится по команде оператор, ' +
            'от основного блока управления.<p>' +

            '<p>Программное обеспечение разработано на языке C, в среде разработки Atmel Studio 6.2. ' +
            'При разработке программы использовалась библиотека yaMBSiavr, реализующая ModBus Slave на микроконтроллерах Atmega.</p>' +

            '<p>Данная система взаимодействует с блоком управления унифицированной роботизированной платформы (БУ УРП), который ' +
            'осуществляет управление данной системой.</p>'
    } as Project,
    {
        id: 'bu-tr',
        title: 'Разработка программного обеспечения блока управления навесным технологическим оборудованием роботизированного траншеекопателя',
        client: 'НПП &laquo;Тензосенсор&raquo;',
        media: ['assets/projects/bu-nto-tr/tr.png', 'assets/projects/bu-nto-tr/structure.png'],
        tech: [
            {
                title: 'Платформа',
                desc: 'Atmel ATmega2560'
            },
            {
                title: 'Язык программирования',
                desc: 'C'
            },
            {
                title: 'Год разработки',
                desc: '2019'
            }
        ],
        description:
            '<p>ООО &laquo;НПП &laquo;Тензосенсор&raquo; разрабатывает роботизированный комплекс для тушения лесных пожаров, ' +
            'построенный на основе гусеничного мотовездехода Tinger Scout. Частью системы управления унифицированной роботизированной ' +
            'платформы является блок управления навесного технологического оборудования - траншеекопатель (БУ НТО ТР).</p>' +

            '<p>Навесное технологическое оборудование представляет собой модуль цепного траншеекопателя, предназначенный для отрытия ' +
            'противопожарных траншей.</p>' +

            '<p>Модуль траншеекопателя размещен в передней части роботизированного траншеекопателя. Модуль подвешен на троссе лебедки, ' +
            'что позволяет поднимать модуль для транспортировки и опускать его для работы.</p>' +

            '<p>Модуль траншеекопателя включает в себя корпус с защитным кожухом, внутри которого установлен двигатель и трансмиссия, ' +
            'и рабочий орган – грунторез (цепную пилу для копания траншей). Грунторез оснащен толкателем, который опускает грунторез, ' +
            'вдавливая его в землю, что обеспечивает отрытие траншеи нужной глубины.</p>' +

            '<p>В корпусе траншеекопателя установлен бензиновый двигатель Honda GX200 с электростартером. Крутящий момент с вала ' +
            'двигателя передается на грунторез через трансмиссию с муфтой, которая срабатывает при увеличении оборотов двигателя.</p>' +

            '<p>Блок управления построен на микроконтроллере Atmega2560. В рамках данного проекта была разработана программа для ' +
            'микроконтроллера, осуществляющая управления всеми исполнительными механизмами системы управления навесным технологическим ' +
            'оборудованием роботизированного траншеекопателя и сбор информации с датчиков. Взаимодействие с блоком управления навесным ' +
            'технологическим оборудованием осуществляется по интерфейсу RS-485, протокол передачи данных Modbus RTU. Данная система ' +
            'осуществляет управление лебедкой подъема грунтореза, крайние положения оборудованы концевиками. Управление толкателем ' +
            'грунтореза осуществляется с помощью шагового двигателя, система оборудована концевиком верхнего положения и датчиком ' +
            'полных оборот, по которому можно судить об уровне заглубления грунтореза. Запуск ДВС грунтореза производится в ' +
            'автоматизированном режиме, по команде оператора. Отключение стартера производится по датчику оборотов ДВС. ' +
            'Управление подсосом может осуществляться как в ручном, так и в автоматизированном режиме.</p>' +

            '<p>Программное обеспечение разработано на языке C, в среде разработки Atmel Studio 6.2. ' +
            'При разработке программы использовалась библиотека yaMBSiavr, реализующая ModBus Slave на микроконтроллерах Atmega.</p>' +

            '<p>Данная система взаимодействует с блоком управления унифицированной роботизированной платформы (БУ УРП), который ' +
            'осуществляет управление данной системой.</p>'
    } as Project,
    {
        id: 'udv35',
        title: 'Модернизация установки диффузионной сварки в вакууме УДВ-35.01УХЛ4',
        client: 'ПАО &laquo;ОДК &ndash; Сатурн&raquo;',
        media: ['assets/projects/udv35/udv35.jpg', 'assets/projects/udv35/dets.jpg'],
        tech: [
            {
                title: 'Платформа',
                desc: 'RealLab NLcon-1AT2'
            },
            {
                title: 'Язык программирования',
                desc: 'C'
            },
            {
                title: 'Год разработки',
                desc: '2019'
            }
        ],
        description:
            '<p>Установка УДВ-35.01УХЛ4 предназначена для диффузионной сварки деталей, изготовленных из разнородных материалов, ' +
            'не свариваемых сваркой плавлением. Штатная система управления установкой неспособна выходить на заданную температуру и ' +
            'поддерживать ее во время выполнения техпроцесса, не имеет системы безопасности в случае обрыва термопары.<p>' +

            '<p>В ходе выполнения модернизации установки были доработаны следующие узлы:</p>' +

            '<ol><li>Система контроля за температурой нагрева детали:' +
            '<ul><li>Система контроля компенсирует инерцию, возникающую при индукционном нагреве свариваемых деталей, ' +
            'в пределах заданных полей допусков температуры сварки детали.</li>' +
            '<li>Предусматривает возможность продолжения процесса сварки при обрыве нижней термопары с контролем температуры нагрева ' +
            'детали по показаниям верхней термопары.</li>' +
            '<li>Позволяет проводить техпроцесс по контролю средней температуры между верхней и нижней термопарой, ' +
            'что максимально близко соответствует температуре в зоне сварки.</li></ul></li>' +

            '<li>Система контроля за мощностью генератора:' +
            '<ul><li>Предусматривает возможность задания максимального допустимого значения мощности генератора с целью:' +
            '<ul><li>предотвращение возможного перегрева деталей в случаи обрыва термопар;</li>' +
            '<li>предотвращения возможного инерционного перегрева свариваемых деталей;</li></ul></li>' +
            '<li>При отработке технологии сварки предусмотрена возможность проведения полного цикла сварки в ручном режиме.</li>' +
            '<li>Предусмотрена возможность перехода на ручной режим, с плавным регулированием мощности генератора ВЧГ в случае сбоя ' +
            'при работе в автоматическом режиме.</li></ul></li>' +

            '<li>Система защиты процесса сварки предусмотривает аварийную сигнализацию при достижении недопустимых значений ' +
            'давления в вакуумной камере.</li></ol>' +

            '<p>В рамах данного проекта был разработан блок контроля температуры, который построен на основе программируемого ' +
            'логического контроллера NLcon-1AT2, в основе которого микроконтроллер ATmega128.</p>' +

            '<p>Блок контроля температуры устанавливается в разрыв шины RS-485 между генератором ВЧГ и ПЛК штатной системы управления ' +
            'и осуществляет преобразование передаваемых по ней пакетов данных, он берет на себя обязанности регулирования температуры сварки. ' +
            'Значения температуры с обоих термопар и уставка поступают в блок контроля температуры с шины RS-485, после чего ' +
            'внутренним ПИД-регулятором происходит расчет необходимой мощности генератора ВЧГ и формируется управляющая посылка.</p>' +

            '<p>На корпусе блока контроля температуры присутствует переключатель используемой термопары: верхняя, нижняя и ' +
            'среднее арифметическое между их показаниями. Так же присутствует переключатель ручного и автоматического режима.</p>' +

            '<p>В ручном режиме мощность генератора устанавливается при помощи ручки на корпусе генератора ВЧГ. ' +
            'В стандартной реализации данной установки такая возможность отсутствует.</p>' +

            '<p>Программное обеспечение разработано на языке C, в среде разработки Atmel Studio 6.2. При разработке программы ' +
            'использовалась модифицированная библиотека yaMBSiavr, в которую была добавлена функция ModBus Master на микроконтроллерах Atmega.</p>'
    } as Project,
    {
        id: 'ngv66',
        title: 'Система управления печью НГВ 6/6',
        client: 'ПАО &laquo;ОДК &ndash; Сатурн&raquo;',
        media: ['assets/projects/ngv66/result.jpg', 'assets/projects/ngv66/3d.png', 'assets/projects/ngv66/3d-back.png',
            'assets/projects/ngv66/mnemonic.png'],
        url: '',
        description: '<p>Установка НГВ 6/6 выполняет вакуумно-дуговое нанесение покрытия. ' +
            'Исполнительными механизмами данной установки являются:\n' +
            '<ul><li>вакуумные насосы;</li>' +
            '<li>система клапанов подачи газа и откачки камеры;</li>' +
            '<li>нагревательные элементы;</li>' +
            '<li>система катодного разряда;</li>' +
            '<li>а также датчики давления, температуры и дискретные датчики состояния системы.</li></ul>' +
            '<p>Вакуумная установка НГВ 6/6 имела устаревший контроллер управления, не выпускающийся на данный момент. ' +
            'Ремонт и содержание системы управления был экономически нецелесообразным. В тоже самое время замена установки подобного ' +
            'класса на современные аналоги также экономически не обоснована.</p>' +
            '<p>Был найден компромисс в данном решение — произвести модернизацию системы управления установок, заменив устаревшие ' +
            'контроллеры на современные аналоги с цифровым сенсорным дисплеем.</p>' +
            '<p>В качестве программируемого-логического контроллера и сенсорной панели был выбран сенсорный панельный контроллер фирмы ' +
            'ОВЕН — СПК110 с подключенными по интерфейсу ModBus RTU расширительными блоками серии МХ110.</p>' +
            '<p>Программное обеспечение реализовано на языке стандарта МЭК61131 — Structured Text в интегрированной среде разработки ' +
            'CoDeSys, поставляемой вместе с сенсорным панельным контроллером.</p>' +
            '<p>Дополнительно разработано программное обеспечение для персонального компьютера на базе операционной системы Windows для ' +
            'вывода и печати графиков процессов и отображения лога работы программного обеспечения с использованием технологий ' +
            'промышленной разработки программного обеспечения на языке Java.</p>'
    } as Project,
    {
        id: 'evt85',
        title: 'Система управления печью ЭВТ 85',
        client: 'ПАО &laquo;ОДК &ndash; Сатурн&raquo;',
        media: ['assets/projects/evt85/result.jpg', 'assets/projects/evt85/3d.png', 'assets/projects/evt85/3d-back.png',
            'assets/projects/evt85/mnemonic.png'],
        url: '',
        description:
            '<p>Установка ЭВТ-85 выполняет вакуумно-дуговое нанесение покрытия. Отличия установки ЭВТ-85 в большем объеме камеры ' +
            'и в наличии дополнительных электрических нагревателей. Исполнительными механизмами данной установки являются:\n' +
            '<ul><li>вакуумные насосы;</li>' +
            '<li>система клапанов подачи газа и откачки камеры;</li>' +
            '<li>нагревательные элементы;</li>' +
            '<li>система катодного разряда;</li>' +
            '<li>а также датчики давления, температуры и дискретные датчики состояния системы.</li></ul>' +
            '<p>Вакуумная установка ЭВТ-85 имела устаревший контроллер управления, не выпускающийся на данный момент. ' +
            'Ремонт и содержание системы управления был экономически нецелесообразным. В тоже самое время замена установки подобного ' +
            'класса на современные аналоги также экономически не обоснована.</p>' +
            '<p>Был найден компромисс в данном решение — произвести модернизацию системы управления установок, заменив устаревшие ' +
            'контроллеры на современные аналоги с цифровым сенсорным дисплеем.</p>' +
            '<p>В качестве программируемого-логического контроллера и сенсорной панели был выбран сенсорный панельный контроллер фирмы ' +
            'ОВЕН — СПК110 с подключенными по интерфейсу ModBus RTU расширительными блоками серии МХ110.</p>' +
            '<p>Программное обеспечение реализовано на языке стандарта МЭК61131 — Structured Text в интегрированной среде разработки ' +
            'CoDeSys, поставляемой вместе с сенсорным панельным контроллером.</p>' +
            '<p>Дополнительно разработано программное обеспечение для персонального компьютера на базе операционной системы Windows для ' +
            'вывода и печати графиков процессов и отображения лога работы программного обеспечения с использованием технологий ' +
            'промышленной разработки программного обеспечения на языке Java.</p>'
    } as Project,
    {
        id: 'gt-integration',
        title: 'Программное обеспечение синхронизации данных информационных систем Teamcenter и TechnologiCS',
        client: 'АО &laquo;ОДК — Газовые турбины&raquo;',
        media: ['assets/projects/gt-integration/teamcenter.png', 'assets/projects/gt-integration/technologics.png',
            'assets/projects/gt-integration/tc-tcs-schema.png', 'assets/projects/gt-integration/tc-tcs-spravka.png'],
        description: '<p>На предприятии АО &laquo;ОДК — Газовые турбины&raquo; с 2016 года происходит внедрение новой ' +
            'информационной систем Siemems Teamcenter, при этом для сохранения работоспособности предприятия и планового выпуска ' +
            'продукции необходимо поддерживать в актуальном состоянии данные информационной системы ЗАО &laquo;Си-Софт&raquo; ' +
            'TechnologiCS, применяемой на предприятии с 2008 г.</p>' +
            '<p>Для реализации данной задачи был разработан программный комплекс синхронизации данных из информационной системы ' +
            'Teamcenter в систему TechnologiCS. Синхронизация данных осуществляется в несколько этапов: ' +
            'выгрузка данных, обработка данных и их загрузка.</p>' +
            '<p>Выгрузка данных осуществляется для сборочных единиц и извещений на изменение, которые прошли утверждение по ' +
            'разработанному бизнес-процессу. Реализация данной задачи использует встроенный в систему Teamcenter модуль экспорта ' +
            'информации в формате PLMXML. Для автоматизации данного процесса разработана утилита на языке Java с применением библиотеки ' +
            'Teamcenter SOA.</p>' +
            '<p>Обработка и загрузка данных в информационную систему TechnologiCS осуществляется программным обеспечением на языке Java. ' +
            'Во время загрузки данных происходит связывание уже имеющейся в базе данных TechnologiCS информации по номенклатуре, ' +
            'спецификации, документам и их связям с выгружаемыми данными из Teamcenter. ' +
            'Связь осуществляется по Teamcenter ID, обозначению, коду материала.</p>',
        tech: [
            {
                title: 'Платформы',
                desc: 'Teamcenter, TechnologiCS'
            }, {
                title: 'Язык программирования',
                desc: 'Java'
            }, {
                title: 'Библиотеки, фреймворки',
                desc: 'Teamcenter SOA, Hibernate'
            }, {
                title: 'Год разработки',
                desc: '2019'
            }
        ]
    } as Project,
    {
        id: 'wms-taxi',
        title: 'Web-приложение для складского учёта',
        client: 'ООО &laquo;Такси-Рыбинск&raquo;',
        media: ['assets/projects/taxi/journal.jpg', 'assets/projects/taxi/information.jpg', 'assets/projects/taxi/creating.jpg',
            'assets/projects/taxi/write-off.jpg'],
        tech: [
            {
                title: 'Язык программирования',
                desc: 'Java, TypeScript'
            },
            {
                title: 'Фреймворк',
                desc: 'Quarkus, Angular'
            },
            {
                title: 'База данных',
                desc: 'PostgreSQL'
            },
            {
                title: 'Дополнительное ПО',
                desc: 'Docker'
            },
            {
                title: 'Год разработки',
                desc: '2020'
            }
        ],
        description: '<p>В настоящее время работа любого складского помещения не обходится без использования соответствующего ' +
            'программного обеспечения. Вся информация о приходе и расходе номенклатуры, её остатках и т. д., должна быть всегда в ' +
            'легкой доступности для работников. Именно поэтому для ООО &laquo;Такси-Рыбинск&raquo; было разработано ' +
            'такое программное обеспечение.</p>' +

            '<p>Был реализован следующий функционал:</p>' +

            '<ul><li>добавление, редактирование и просмотр информации о закупке, остатке и списании запчастей;</li>' +
            '<li>добавление, редактирование и просмотр информации о слесарях, водителях и автомобилях на ' +
            'которых списываются запчасти;</li>' +
            '<li>построение различных отчетов, позволяющих отслеживать информацию о состоянии запчастей, ' +
            'а также о затратах на их покупку;</li>' +
            '<li>аутентификация и авторизация пользователей на выполнение тех или иных действий, что упрощает работу в приложении и ' +
            'предостерегает работников от неверных действий;</li>' +
            '<li>для администратора системы реализован функционал для просмотра логов, с помощью которого, он может отслеживать какие ' +
            'действия совершают пользователи.</li></ul>'
    } as Project,
    {
        id: 'aesthetica',
        title: 'Сайт студии красоты и релакса AESTHETICA',
        url: 'http://rybinsk-aestetica.ru/',
        media: ['assets/projects/aesthetica/about.png', 'assets/projects/aesthetica/personal.png', 'assets/projects/aesthetica/login.png',
            'assets/projects/aesthetica/clients.png', 'assets/projects/aesthetica/masters.png', 'assets/projects/aesthetica/services.png',
            'assets/projects/aesthetica/records.png', 'assets/projects/aesthetica/report.png'],
        description: '<p>Сайт студии красоты и релакса AESTHETICA (г. Рыбинск) содержит страницы с перечнем услуг, информацией о ' +
            'мастерах и режиме работа салона красоты. Админисратору доступен раздел с классификатором услуг, информацией о клиентах, ' +
            'мастерах и записях на прием к специалистам.</p>' +
            '<p>Администратор может выполнять добавление и редактирование информации о клиентах, редактировать услуги в классификаторе, ' +
            'записывать посетителей на прием в назначенную дату и время. Стоимость услуг привязана к мастеру и может быть изменена персонально ' +
            'для клиента. По результатам работы за определенный период можно составить отчёт о работе мастера.</p>',
        tech: [
            {
                title: 'Фреймворк',
                desc: 'Quarkus, Angular'
            }, {
                title: 'Язык программирования',
                desc: 'Java, TypeScript'
            }, {
                title: 'Год разработки',
                desc: '2019'
            }
        ]
    } as Project,
    {
        id: 'sobering',
        title: 'Сайт Федерация трезвости',
        client: 'ЯРОО &laquo;Федерация Трезвости&raquo;',
        description: '<p>По заказу ярославской региональной общественной организации по противодействию распространения наркомании и ' +
            'алкоголизма &laquo;Федерация трезвости&raquo; был разработан форум, на котором осуществляется взаимодействие социальных и ' +
            'политических деятелей, врачей, специалистов по работе с детьми, горожан и других людей с целью освещения проблемы и ' +
            'принятия стратегических решений направленных на противодействие распространению наркомании и алкоголизма.</p>' +
            '<p>Данный форум имеет разбиение на закрытые группы — консорциумы, целью которых является решение одного или группы ' +
            'поставленных вопросов. В рамках таких групп происходит обсуждение некоторого вопроса, руководитель группы должен принять ' +
            'решение по поставленному вопросу исходя из мнений членов его рабочей группы. Сообщения, которые руководитель группы считает ' +
            'полезными, отмечаются как &laquo;Решение&raquo; и выделяются зеленым цветов, а в верху обсуждения появляется ответ, который ' +
            'составляется из таких сообщений. Обсуждение на форуме происходит в древовидном режиме, при этом сохраняется возможность ' +
            'проследить ход обсуждения, а также вести несколько веток обсуждения одной темы параллельно.</p>' +
            '<p>При разработке такой гибкой платформы применялись технологии разработки Web-сайтов на языке PHP и фреймворке CodeIgnite ' +
            'для упрощения дальнейшей технической поддержки сайта.</p>' +
            '<p>Программное обеспечение упаковано в Docker-контейнеры, что позволяет производить запуск и миграцию между серверами без ' +
            'дополнительного конфигурирования машины.</p>',
        media: ['assets/projects/sobering.jpg'],
        url: 'http://sobering.ru/',
        tech: [
            {
                title: 'Язык программирования',
                desc: 'PHP, JavaScript'
            },
            {
                title: 'Фреймворк',
                desc: 'CodeIgnite, jQuery'
            },
            {
                title: 'Сторонние ресурсы',
                desc: 'Интеграция с Yandex.Maps API'
            },
            {
                title: 'Год разработки',
                desc: '2017'
            }
        ]
    } as Project
];
