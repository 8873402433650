import {Component, EventEmitter, OnInit} from '@angular/core';
import {ProjectsService} from '../../services/projects.service';
import {PageEvent} from '@angular/material/paginator';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.css', '../../common.css']
})
export class ProjectListComponent implements OnInit {

    projects = [];

    length = 0;
    pageSize = 10;
    pageSizeOptions: number[] = [10, 25, 100];

    constructor(private projectService: ProjectsService) {
    }

    ngOnInit(): void {
        this.loadPage(0);
    }

    changePage(pageEvent: PageEvent): void {
        this.pageSize = pageEvent.pageSize;
        this.loadPage(pageEvent.pageIndex);
    }

    loadPage(page: number): void {
        this.projectService.getProjects(page, this.pageSize).subscribe(
            projects => {
                this.projects = projects.value;
                this.length = projects.total;
            }, error => {
                console.error(error);
            }
        );
    }

}
