import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.css']
})
export class PhotoViewerComponent implements OnInit {

  imageIndex = 0;
  @Input()
  images: Array<string>;

  canOpenDialog = false;

  constructor(public dialogRef: MatDialogRef<PhotoViewerComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialog: MatDialog) {
    this.canOpenDialog = !data.isDialog;
    this.images = data.images;
  }

  ngOnInit(): void {
  }

  openInDialog(): void {
    if (this.canOpenDialog) {
      const projDialogRef = this.dialog.open(PhotoViewerComponent, {
        height: '95vh',
        width: '95vw',
        data: {
          images: this.images,
          isDialog: true
        }
      });
    }
  }

  nextImage(): void {
    this.imageIndex++;
    if (this.imageIndex >= this.images.length) {
      this.imageIndex = 0;
    }
  }

  prevImage(): void {
    this.imageIndex--;
    if (this.imageIndex < 0) {
      this.imageIndex = this.images.length - 1;
    }
  }

}
